<template>
  <GlobalFooter class="i-copyright" :links="links" :copyright="copyright" />
</template>
<script>
  import dayjs from 'dayjs'
  export default {
    name: 'i-copyright',
    data() {
      return {
        links: [
          // {
          //   title: '吾家新寓官网',
          //   key: '吾家新寓官网',
          //   href: 'https://www.giantfind.com.cn/#/index',
          //   blankTarget: true,
          // },
          // {
          //   title: '粤ICP备18120239号',
          //   key: '粤ICP备18120239号',
          //   href: 'https://beian.miit.gov.cn/#/Integrated/index',
          //   blankTarget: true,
          // },
          // {
          //   title: '粤公网安备 44010602005737号',
          //   key: '粤公网安备 44010602005737号',
          //   href: 'https://beian.miit.gov.cn/#/Integrated/index',
          //   blankTarget: true,
          // },
        ],
        copyright: `© ${dayjs().format('YYYY')} 河北雄安保障房安居工程有限公司`,
      }
    },
  }
</script>
<style lang="less">
  .ivu-global-footer-links a {
    display: block;
    margin-right: 0 !important;
  }
  .ivu-global-footer {
    margin: 48px 0 24px 0;
    text-align: center;
  }
</style>
