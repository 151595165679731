/**
 * 注册、登录、注销
 * */
import {get, cloneDeep} from 'lodash'
import { jumpSSOLogin, transferMenusList } from '@/libs/util'
import { queryEmployeeInfoForLoginUser } from '@api/sys'
import { foreEndMenusList } from '@/api/sys'
import { storePersonnel } from '@/api/dashboard'
import { Modal } from 'view-design'
import storage from '@/libs/vendors/storage'

const companyListSort = (result) => {
  return result.sort(item => {
    if (item.companyOrgName.includes('广东吾家新寓')) return -2
    if (item.companyOrgName.includes('吾家新寓SA')) return -1
    return 0
  })
}
export default {
  namespaced: true,
  state: {
    menuList: [],
    menuDefaultList: [],
  },
  actions: {
    login({dispatch, state}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          storage.set('token', payload.accessToken)
          // 查询用户详情
          const { result } = await queryEmployeeInfoForLoginUser({userId: payload.id})

          const companyList = companyListSort(result)
          const userInfo = get(companyList, '0', {})
          const user = {
            ...payload,
            ...userInfo,
            companyList,
            uuid: userInfo.id,
            userName: userInfo.name,
            companyId: userInfo.companyOrgId,
            employeeId: userInfo.id,
          }
          storage.set('user', user)
          await dispatch('autoLogin', user)
          resolve()
        } catch (e) { reject(e) }
      })
    },
    /**
     * @description 退出登录
     * */
    logout({ commit, dispatch }, { confirm = false, vm, rentuserlogin = false } = {}) {
      const logout = () => {
        dispatch('admin/user/set', {}, {root: true})
        rentuserlogin !== true && jumpSSOLogin({ logout: true })
      }

      if (confirm) {
        return Modal.confirm({
          title: '退出登录确认',
          content: '您确定退出登录当前账户吗',
          onOk: logout
        })
      }
      logout()
    },
    autoLogin({dispatch, state}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          // 设置 vuex 用户信息
          await dispatch('admin/user/set', payload, {root: true})
          // 获取用户菜单
          await dispatch('foreEndMenusListReq')
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          resolve()
        } catch (e) { reject(e) }
      })
    },

    /**
     * 切换公司
     */
    companyChangeFn({ dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          storage.set('token', payload.accessToken, payload.expiration)

          // 查询用户详情
          const { result } = await queryEmployeeInfoForLoginUser({userId: payload.userId})
          const companyList = companyListSort(result)
          const user = {
            ...payload,
            companyList,
            id: payload.employeeId,
            uuid: payload.employeeId,
            companyOrgId: payload.companyId,
          }

          storage.set('user', user, payload.expiration)
          // 设置 vuex 用户信息
          await dispatch('admin/user/set', user, {root: true})
          await dispatch('admin/page/closeAll', {}, {root: true})
          // 获取用户菜单
          await dispatch('foreEndMenusListReq')
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          resolve()
        } catch (e) { reject(e) }
      })
    },
    foreEndMenusListReq({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        foreEndMenusList().then(async ({ result }) => {
          if (result == null || !result.length) return resolve()

          state.menuList = transferMenusList(cloneDeep(result))
          state.menuDefaultList = result
          resolve()
        })
      })
    },

    /**
     * 获取门店
     */
    async getStoreList({ dispatch, rootState }) {
      const res = await storePersonnel({ userId: rootState.admin.user.info.id })

      await dispatch('admin/user/setStoreList', res.result, { root: true })
    },

    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     */
    load({ state, dispatch }) {
      return new Promise(async resolve => {
        // 持久化数据加载上次退出时的多页列表
        await dispatch('admin/page/openedLoad', null, { root: true });
        // end
        resolve();
      })
    },
  }
}
