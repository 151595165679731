import BasicLayout from '@/layouts/basic-layout'

const prefix = 'activity-'
export default [
  {
    path: '/activity',
    redirect: {name: `${prefix}student`},
    component: BasicLayout,
    children: [
      {
        path: 'student',
        name: `${prefix}student`,
        meta: {title: '202205毕业季活动'},
        component: () => import('@/pages/activity/student')
      },
      {
        path: 'handClap',
        name: `${prefix}handClap`,
        meta: {title: '隐患随手拍、安全吹哨人'},
        component: () => import('@/pages/activity/handClap')
      },
      {
        path: 'channel',
        name: `${prefix}channel`,
        meta: {title: '活动渠道'},
        component: () => import('@/pages/activity/channel')
      },
      {
        path: 'safety',
        name: `${prefix}-safety`,
        meta: {title: '吾家新寓出租屋安全隐患调查表'},
        component: () => import('@/pages/activity/safety')
      },

      {
        path: 'questionnaire',
        name: `${prefix}questionnaire`,
        redirect: () => ({name: `${prefix}customerManagement`})
      },
      {
        path: 'questionnaire/customer/:type(\\d+)?',
        name: `${prefix}customerIndex`,
        redirect: to => ({name: `${prefix}-customer`, params: {type: to.params.type}})
      },
      {
        path: 'questionnaire/customer/createQRCode/:type(\\d+)?',
        name: `${prefix}-customer-createQRCode`,
        meta: {title: '生成物料'},
        component: () => import('@/pages/activity/questionnaire/customer/createQRCode')
      },
      {
        path: 'questionnaire/customer/list/:type(\\d+)?',
        name: `${prefix}-customer`,
        meta: {title: '问卷列表'},
        component: () => import('@/pages/activity/questionnaire/customer/list')
      },
      {
        path: 'questionnaire/gjj',
        name: `${prefix}-questionnaire-gjj`,
        meta: {title: '公积金活动问卷'},
        component: () => import('@/pages/activity/questionnaire/gjj')
      },
    ]
  }
]
